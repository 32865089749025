<script setup>
import { onMounted, defineProps, defineEmits, ref, computed, watch } from 'vue';
// @import componnets
import PlayListModal from '@/components/modals/forms/PlayListModal.vue';
import VideoContentModal from '@/components/modals/forms/VideoContentModal.vue';
import TemplateContentModal from '@/components/modals/forms/TemplateContentModal.vue';
// @import utils
import ApiService from '@/core/services/ApiService';

const props = defineProps({
	data: Array,
	expandedData: Array
});

const emit = defineEmits(['callback']);

const pagination = {
	paging: {
		pageNumber: 1,
		pageSize: 100
	},
	filter: {
		searchTerm: ''
	},
	orderBy: {
		orderByField: '',
		orderDesc: false
	}
};

const activeModalKey = ref('');
const relatedContentArray = ref([]);
const componentListArray = ref([]);
const contentListArray = ref([]);
const relatedDropDown = ref({
	selectedvalue: '',
	entityType: '',
	contentType: '',
	contentName: '',
	templateId: '',
	buttonLabel: ''
});

const options = ref({
	// delay: 300,
	draggable: '.draggable',
	handle: '.draggableHandle',
	sortAnimation: {
		duration: 200,
		easingFunction: 'ease-in-out'
	}
	// plugins: [Draggable.Plugins.SortAnimation]
});

watch(props, to => {
	if (to.data.length) {
		relatedContentArray.value = to.data;
	}
});

const getRelatedContentArray = computed(() => {
	return relatedContentArray.value;
});

const getTitle = content => {
	// console.log('content: ', content);
	if (content.title) return content.title;
	const matchedContent = props.expandedData.find(
		x => x.contentId == content.contentId
	);
	return matchedContent ? matchedContent.title : '';
};

const getTargetedModal = computed(() => {
	if (relatedDropDown.value.entityType == 'VideoContent')
		return '#kt_modal_videocontent_modal';
	if (relatedDropDown.value.entityType == 'Playlist')
		return '#kt_modal_playlist_modal';
	if (relatedDropDown.value.entityType == 'TemplateContent')
		return '#kt_modal_content_modal';
});

onMounted(() => {
	// relatedContentArray.value = data;
	getDropdownData();
});

const getDropdownData = async () => {
	// fetch components
	try {
		const componentListAResult = await ApiService.post(
			`Template/list/Component`,
			pagination
		);
		componentListArray.value = componentListAResult.data;
	} catch (error) {
		console.log('getComponentList error response', response);
	}

	// fetch contents
	try {
		const contentListArrayResult = await ApiService.post(
			`Template/list/Page`,
			pagination
		);
		contentListArray.value = contentListArrayResult.data;
	} catch (error) {
		console.log('getContentList error response', response);
	}
};

const sorted = e => {
	relatedContentArray.value = array_move(
		relatedContentArray.value,
		e.data.oldIndex,
		e.data.newIndex
	);
	emit('callback', relatedContentArray.value);
};

const array_move = (arr, from, to) => {
	return arr.map((item, i) =>
		i === to
			? arr[from]
			: i >= Math.min(from, to) && i <= Math.max(from, to)
			? arr[i + Math.sign(to - from)]
			: item
	);
};

const setModalKey = mode => {
	activeModalKey.value = mode;
};

const addRelatedContent = item => {
	let title;
	if (relatedDropDown.value.entityType == 'TemplateContent') {
		title = item.templateContentFields.displayTitle;
	} else {
		title = item.title;
	}
	// console.log('item: ', item.templateContentFields.displayTitle);
	const contentData = {
		entityType: relatedDropDown.value.entityType,
		contentType: relatedDropDown.value.contentType,
		contentName: relatedDropDown.value.contentName,
		contentId: item.id,
		title: title
	};

	relatedContentArray.value.push(contentData);

	emit('callback', relatedContentArray.value);
	// reinitializeComponents();
};

const setRelatedContentDropdown = item => {
	const splitContent = item.split('||');
	if (splitContent.length > 1) {
		relatedDropDown.value.entityType = 'TemplateContent';
		relatedDropDown.value.contentType = splitContent[0];
		relatedDropDown.value.contentName = splitContent[1];
		relatedDropDown.value.templateId = splitContent[2];
		relatedDropDown.value.buttonLabel = splitContent[0];
	} else {
		relatedDropDown.value.entityType = splitContent[0];
		relatedDropDown.value.contentType = '';
		relatedDropDown.value.contentName = '';
		relatedDropDown.value.templateId = '';
		relatedDropDown.value.buttonLabel = splitContent[0];
	}
};

const deleteRelatedContent = contentId => {
	relatedContentArray.value = relatedContentArray.value.filter(
		item => item.contentId != contentId
	);
	emit('callback', relatedContentArray.value);
};
</script>

<template>
	<!--begin:: Related Content Card-->
	<pre class="d-none"
		>{{ getRelatedContentArray }}, {{ contentListArray }}, {{
			componentListArray
		}}</pre
	>
	<vue-sortable :options="options" @sortable:stop="sorted" :key="Math.random()">
		<div class="card mt-5">
			<div class="card-body">
				<!-- begin:: list of selected content -->
				<vue-draggable-container tag="ul" class="ps-1 m-0">
					<li
						:key="`content_${index}`"
						v-for="(content, index) in getRelatedContentArray"
						class="draggable card border border-primary mb-5"
						:class="{
							'mb-10': getRelatedContentArray.length == index + 1
						}"
					>
						<span
							style="top: 8px; left: -20px; cursor: grab"
							class="draggableHandle svg-icon svg-icon-7 svg-icon-primary position-absolute"
						>
							<inline-svg
								class="w-100"
								style="transform: rotate(45deg)"
								src="/media/icons/duotune/arrows/arr035.svg"
							/>
						</span>

						<div
							class="card-body p-5 d-flex justify-content-between align-items-center"
						>
							<div class="d-flex flex-column">
								<strong v-if="getTitle(content)"
									>Title: {{ getTitle(content) }}</strong
								>
								<span class="text-muted"
									>Entity type: {{ content.entityType }}</span
								>
								<span class="text-muted mt-1">ID: {{ content.contentId }}</span>
								<div class="d-flex mt-1" v-if="content.contentType">
									<span class="text-muted me-10"
										>contentName: {{ content.contentName }}</span
									>
									<span class="text-muted me-10"
										>ContentType: {{ content.contentType }}</span
									>
								</div>
							</div>

							<div class="d-flex">
								<button
									type="button"
									@click="deleteRelatedContent(content.contentId)"
									class="btn btn-sm btn-icon btn-color-gray btn-white btn-active-light-primary me-2"
								>
									<span class="svg-icon svg-icon-2">
										<inline-svg src="media/icons/duotune/general/gen027.svg" />
									</span>
								</button>
							</div>
						</div>
					</li>
					<!-- end:: list of selected content -->
				</vue-draggable-container>

				<h2 class="mb-5">Related Content</h2>
				<div class="fv-row d-flex flex-column">
					<label class="fs-6 fw-bold mb-2">Select a content</label>
					<el-select
						filterable
						class="w-100"
						default-first-option
						id="relatedContentDropdown"
						placeholder="Select an option..."
						v-model="relatedDropDown.selectedvalue"
						@change="setRelatedContentDropdown"
					>
						<el-option-group label="">
							<el-option label="VideoContent" value="VideoContent" />
							<el-option label="Playlist" value="Playlist" />
						</el-option-group>
						<el-option-group label="Content">
							<el-option
								v-for="(item, index) in contentListArray"
								:key="`content_${index}`"
								:label="item.contentName"
								:value="`${item.contentType}||${item.contentName}||${item.templateId}`"
							/>
						</el-option-group>
						<el-option-group label="Component">
							<el-option
								v-for="(item, index) in componentListArray"
								:key="`component_${index}`"
								:label="item.contentName"
								:value="`${item.contentType}||${item.contentName}||${item.templateId}`"
							/>
						</el-option-group>
					</el-select>

					<button
						type="button"
						data-bs-toggle="modal"
						:data-bs-target="getTargetedModal"
						@click="setModalKey(relatedDropDown.entityType)"
						v-if="relatedDropDown.entityType"
						:class="`btn btn-outline btn-outline-primary bg-light-primary mt-4 me-auto`"
					>
						<span
							class="d-inline-block svg-icon svg-icon-2 svg-icon-primary align-text-bottom"
						>
							<inline-svg src="media/icons/duotune/general/gen035.svg" />
						</span>
						Select {{ relatedDropDown.buttonLabel }}
					</button>
				</div>
			</div>
		</div>
	</vue-sortable>
	<!--end:: Related Content Card-->

	<PlayListModal
		id="kt_modal_playlist_modal"
		@setSelectedPlayList="addRelatedContent"
	/>
	<VideoContentModal
		id="kt_modal_videocontent_modal"
		@setSelectedVideoContent="addRelatedContent"
	/>
	<TemplateContentModal
		id="kt_modal_content_modal"
		:templateType="relatedDropDown.contentName"
		:templateId="relatedDropDown.templateId"
		@callback="addRelatedContent"
	/>
</template>
