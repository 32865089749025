
import { defineComponent, onMounted, ref, computed } from 'vue';
import moment from 'moment';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { QuillEditor } from '@vueup/vue-quill';
import Swal from 'sweetalert2/dist/sweetalert2.js';
// @import utils
import ApiService from '@/core/services/ApiService';
import { IUserLevel } from '@/core/data/userLevel';
import { IEnumerationTypes } from '@/core/data/enumerationTypes';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
// @import components
import AddVideoAsset from '@/components/modals/forms/AddVideoAsset.vue';
import PlayListModal from '@/components/modals/forms/PlayListModal.vue';
import PricePlanModal from '@/components/modals/forms/PricePlanModel.vue';
import RelatedContent from '@/components/related-content/RelatedContent.vue';
import CMSImageListModel from '@/components/modals/forms/CMSImageListModel.vue';
import VideoContentModal from '@/components/modals/forms/VideoContentModal.vue';
import TemplateContentModal from '@/components/modals/forms/TemplateContentModal.vue';

export default defineComponent({
	name: 'Update-Video-Content',
	components: {
		QuillEditor,
		AddVideoAsset,
		PlayListModal,
		RelatedContent,
		PricePlanModal,
		VideoContentModal,
		CMSImageListModel,
		TemplateContentModal
	},
	setup() {
		const route = useRoute();
		const store = useStore();

		const imageModel = ref<any>(null);
		const contentEditor = ref<any>(null);
		const descriptionEditor = ref<any>(null);
		const formRef = ref<null | HTMLFormElement>(null);

		const imageKey = ref('');
		const videoKey = ref('mediaId');
		const defaultFilterType = ref('');
		const videoAssetObj = ref<any>({});
		const selectedPricingPlans = ref<any>([]);
		const extraVideoAssetsArray = ref<any>([]);
		const extraVideoAssetsIdArray = ref<any>([]);
		const relatedPlayListObj = ref<any>({});
		const relatedContentArray = ref<any>([]);
		const relatedContentsExpanded = ref<any>([]);
		const loading = ref<boolean>(false);
		const userLevel = ref<Array<IUserLevel>>([]);
		const enumerationsType = ref<Array<IEnumerationTypes>>([]);

		const isEdit = route.params.id;

		const formData = ref({
			id: '',
			mediaId: '',
			published: false,
			public: false,
			userLevels: [],
			pricingPlans: '',
			title: '',
			subtitle: '',
			slug: '',
			sortOrder: 0,
			relatedPlayList: '',
			author: '',
			publicationDate: '',
			shortDescription: '',
			content: '',
			overviewImage: '',
			overviewImageUrl: '',
			detailImage: '',
			detailImageUrl: '',
			category: '',
			mediaShortId: '',
			mediaTrailerId: '',
			additionalFields: [],
			freeVideo: false,
			hasPublicationSchedule: false,
			publicationScheduleFrom: '',
			publicationScheduleUntil: '',
			created: '',
			updated: '',
			relatedContents: ''
		});

		const fieldsData = ref({
			label: '',
			url: '',
			graden: '',
			themas: '',
			genre: '',
			country: '',
			duration: '',
			dialog: '',
			subtitle: '',
			director: '',
			year: '',
			festival: '',
			rating: '',
			degrees: '',
			tag: '',
			theme: '',
			blogauthor: '',
			blogtype: '',
			cast: '',
			journeys: '',
			level: '',
			recipies: '',
			role: '',
			teachers: '',
			yogastyles: '',
			yogatype: ''
		});

		const rules = ref({
			title: [
				{
					required: true,
					message: 'Title is required',
					trigger: 'change'
				}
			],
			slug: [
				{
					required: true,
					message: 'URL is required',
					trigger: 'change'
				}
			],
			mediaId: [
				{
					required: true,
					message: 'Video is required',
					trigger: 'change'
				}
			],
			publicationDate: [
				{
					required: true,
					message: 'Publication Date is required',
					trigger: 'change'
				}
			]
		});

		const selectedCustomer = computed(() => {
			return store.getters.selectedCustomer;
		});

		onMounted(() => {
			setCurrentPageBreadcrumbs(`${isEdit ? 'Edit' : 'Add'} Video Content`, [
				'Video Content'
			]);
			if (isEdit) {
				getVideoContentById();
			}
			getUserLevels();
			getEnumerationsType();
		});

		// TO DO
		const getPlayListDataById = mId => {
			ApiService.get(`PlayList/${mId}`)
				.then(({ data }) => {
					relatedPlayListObj.value = data;
				})
				.catch(({ response }) => {
					console.log('error', response);
				});
		};

		const getVideoAssetById = (mId, videoKey) => {
			ApiService.get(`VideoAsset/${mId}`)
				.then(({ data }) => {
					if (videoKey === 'extraVideoAssets') {
						if (!extraVideoAssetsIdArray.value.includes(data.mediaId)) {
							extraVideoAssetsArray.value.push(data);
							extraVideoAssetsIdArray.value.push(data.mediaId);
						}
					} else {
						videoAssetObj.value = {
							...videoAssetObj.value,
							[videoKey]: data
						};
					}
				})
				.catch(({ response }) => {
					console.log('error', response);
				});
		};

		const getExtraVideoContentById = mId => {
			ApiService.get(`VideoContent/${mId}`)
				.then(({ data }) => {
					setSelectedVideoContent(data);
				})
				.catch(({ response }) => {
					console.log('error', response);
				});
		};

		const getextraVideoAssets = extraData => {
			for (const item of extraData) {
				getExtraVideoContentById(item);
			}
		};

		const getThumbnailData = assetData => {
			if (assetData.mediaId) {
				getVideoAssetById(assetData.mediaId, 'mediaId');
			}
			if (assetData.mediaShortId) {
				getVideoAssetById(assetData.mediaShortId, 'mediaShortId');
			}
			if (assetData.mediaTrailerId) {
				getVideoAssetById(assetData.mediaTrailerId, 'mediaTrailerId');
			}
		};

		const getVideoContentById = () => {
			ApiService.get(`VideoContent/${route.params.id}`)
				.then(({ data }) => {
					formData.value = {
						...formData.value,
						...data,
						userLevels:
							data.userLevels.indexOf(',') != -1 || data.userLevels != ''
								? data.userLevels.split(',')
								: (data.userLevels = [])
					};
					if (data && data.additionalFields) {
						for (const tab of data.additionalFields) {
							for (const field of tab.fields) {
								if (field.fieldType === 'EnumMulti') {
									const valArry = field.values
										.filter(item => item.value)
										.map(ele => ele.value);
									fieldsData.value = {
										...fieldsData.value,
										[field.fieldName]: valArry
									};
								} else {
									fieldsData.value = {
										...fieldsData.value,
										[field.fieldName]: field.value
									};
								}
							}
						}
					}

					if (data && data.relatedContents) {
						relatedContentArray.value = data.relatedContents;
						relatedContentsExpanded.value = data.relatedContentsExpanded;
					}

					if (data && data.pricingPlans) {
						getPricePlanList();
					}

					if (data && data.relatedPlayList) {
						getPlayListDataById(data.relatedPlayList);
					}

					getThumbnailData(data);
					if (data?.extraVideoAssets) {
						getextraVideoAssets(data?.extraVideoAssets);
					}

					contentEditor.value.setHTML(data.content);
					descriptionEditor.value.setHTML(data.shortDescription);
				})
				.catch(({ response }) => {
					console.log('error', response);
				});
		};

		const getEnumerationsType = () => {
			ApiService.get('TabFields/videocontent')
				.then(({ data }) => {
					// console.log('enumerationsType response: ', data);
					enumerationsType.value = data;
				})
				.catch(({ response }) => {
					console.log('response', response);
				});
		};

		const getUserLevels = () => {
			ApiService.get('UserLevel')
				.then(({ data }) => {
					// console.log('UserLevel response: ', data);
					userLevel.value = data;
				})
				.catch(({ response }) => {
					console.log('response', response);
				});
		};

		const getPricePlanList = () => {
			ApiService.get(`PricePlan`)
				.then(({ data }) => {
					console.log('formData', data, formData.value.pricingPlans);
					const filteredArray =
						data &&
						data.filter(el =>
							formData.value.pricingPlans.split(',').some(item => {
								console.log('loop', item, '--', el.pricePlanId);
								return item == el.pricePlanId;
							})
						);

					selectedPricingPlans.value = filteredArray;
				})
				.catch(({ response }) => {
					console.log('getPricePlanList response error:', response);
					loading.value = false;
				});
		};

		const setAdditionalField = () => {
			if (enumerationsType.value.length) {
				const addField = enumerationsType.value?.map(tab => {
					const tabItem = {
						tabName: tab?.jsonProperty,
						fields: tab?.fields.map(field => {
							if (field.fieldType === 'EnumMulti') {
								return {
									fieldName: field.jsonProperty,
									fieldType: field.fieldType,
									values: fieldsData.value[field.jsonProperty].map(item => {
										return { value: item };
									})
								};
							} else {
								return {
									fieldName: field.jsonProperty,
									fieldType: field.fieldType,
									value: fieldsData.value[field.jsonProperty]
								};
							}
						})
					};
					return tabItem;
				});
				return addField;
			}
			return [];
		};

		const setSelectedPlayList = row => {
			relatedPlayListObj.value = row;
			formData.value = {
				...formData.value,
				relatedPlayList: row.id
			};
		};

		const resetPlayListObject = () => {
			relatedPlayListObj.value = {};
			formData.value = {
				...formData.value,
				relatedPlayList: ''
			};
		};

		const setSelectedPricePlan = item => {
			// console.log('item:', item);
			selectedPricingPlans.value.push(item);
		};

		const resetPricingPlansObject = itemId => {
			selectedPricingPlans.value = selectedPricingPlans.value.filter(
				e => e.pricePlanId !== itemId
			);
		};

		const setSelectedVideoAsset = row => {
			defaultFilterType.value = '';
			if (videoKey.value === 'extraVideoAssets') {
				if (!extraVideoAssetsIdArray.value.includes(row.mediaId)) {
					extraVideoAssetsArray.value.push(row);
					extraVideoAssetsIdArray.value.push(row.mediaId);
				}
			} else {
				videoAssetObj.value = {
					...videoAssetObj.value,
					[videoKey.value]: row
				};
				if (videoKey.value === 'mediaId') {
					formData.value = {
						...formData.value,
						title: row.title,
						slug: row.slug,
						mediaId: row.mediaId,
						publicationDate: row.updatedAt,
						shortDescription: row.description
					};
				} else if (videoKey.value === 'mediaShortId') {
					formData.value = {
						...formData.value,
						mediaShortId: row.mediaId
					};
				} else if (videoKey.value === 'mediaTrailerId') {
					formData.value = {
						...formData.value,
						mediaTrailerId: row.mediaId
					};
				}
			}
		};

		const setSelectedVideoContent = row => {
			if (!extraVideoAssetsIdArray.value.includes(row.id)) {
				extraVideoAssetsArray.value.push(row);
				extraVideoAssetsIdArray.value.push(row.id);
			}
		};

		const resetVideoObject = key => {
			videoAssetObj.value = {
				...videoAssetObj.value,
				[key]: null
			};
			formData.value = {
				...formData.value,
				[key]: ''
			};
		};

		const setSelectedImage = image => {
			// console.log('image: ', image);
			// console.log('imageKey: ', imageKey.value);
			formData.value = {
				...formData.value,
				[imageKey.value]: image.id
			};

			if (imageKey.value == 'overviewImage') {
				formData.value = { ...formData.value, overviewImageUrl: image.url };
			}
			if (imageKey.value == 'detailImage') {
				formData.value = { ...formData.value, detailImageUrl: image.url };
			}
		};

		const setImageKey = key => {
			imageKey.value = key;
		};

		const setVideoKey = key => {
			videoKey.value = key;
		};

		const resetVideoContentObject = id => {
			extraVideoAssetsArray.value = extraVideoAssetsArray.value.filter(
				e => e.id !== id
			);
			extraVideoAssetsIdArray.value = extraVideoAssetsIdArray.value.filter(
				e => e !== id
			);
		};

		const copy = async () => {
			var text = formData.value.title + " || " + formData.value.id;
			await navigator.clipboard.writeText(text);
		}

		const submit = () => {
			if (!formRef.value) {
				return;
			}

			if (selectedPricingPlans.value.length) {
				const pricingPlansIds = selectedPricingPlans.value.map(
					item => item.pricePlanId
				);
				formData.value.pricingPlans = pricingPlansIds.join();
			}
			let publicationDetail = {};
			if (formData.value.hasPublicationSchedule) {
				publicationDetail = {
					publicationScheduleFrom: formData.value.publicationScheduleFrom
						? moment(formData.value.publicationScheduleFrom).format(
								'YYYY-MM-DD HH:mm'
						  )
						: '',
					publicationScheduleUntil: formData.value.publicationScheduleUntil
						? moment(formData.value.publicationScheduleUntil).format(
								'YYYY-MM-DD HH:mm'
						  )
						: ''
				};
			}
			formRef.value.validate(valid => {
				if (valid && formData.value.mediaId) {
					loading.value = true;
					const data: any = {
						...formData.value,
						...publicationDetail,
						relatedContents: relatedContentArray.value,
						userLevels: formData.value.userLevels.toString(),
						additionalFields: setAdditionalField(),
						customerId: selectedCustomer.value,
						extraVideoAssets: extraVideoAssetsIdArray.value,
						publicationDate: moment(formData.value.publicationDate).format(
							'YYYY-MM-DD'
						)
					};
					let serviceType;
					if (isEdit) {
						serviceType = ApiService.put(
							`VideoContent/${route.params.id}?VideoContentId=${route.params?.id}`,
							data
						);
					} else {
						delete data['id'];
						serviceType = ApiService.post('VideoContent', data);
					}

					serviceType
						.then(({ data }) => {
							// console.log('create VideoContent response', data);
							loading.value = false;
							// redirect to main page ?
							Swal.fire({
								timer: 1500,
								text: 'Form has been successfully submitted!',
								icon: 'success',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							}).then(() => {
								if (isEdit) {
									formData.value = {
										...formData.value,
										...data,
										userLevels:
											data.userLevels.indexOf(',') != -1 ||
											data.userLevels != ''
												? data.userLevels.split(',')
												: (data.userLevels = [])
									};
								} else {
									(
										document.getElementById('formRef') as HTMLFormElement
									).reset();
								}
							});
						})
						.catch(({ response }) => {
							console.log('create video content error', response);
							Swal.fire({
								text: 'Sorry, looks like there are some errors detected, please try again.',
								icon: 'error',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							});
						});
				} else {
					Swal.fire({
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
					return false;
				}
			});
		};

		return {
			rules,
			moment,
			isEdit,
			copy,
			submit,
			formRef,
			loading,
			formData,
			userLevel,
			fieldsData,
			imageModel,
			setImageKey,
			setVideoKey,
			videoAssetObj,
			contentEditor,
			enumerationsType,
			setSelectedImage,
			resetVideoObject,
			defaultFilterType,
			descriptionEditor,
			relatedPlayListObj,
			relatedContentArray,
			relatedContentsExpanded,
			resetPlayListObject,
			setSelectedPlayList,
			setSelectedPricePlan,
			resetVideoContentObject,
			setSelectedVideoAsset,
			selectedPricingPlans,
			resetPricingPlansObject,
			setSelectedVideoContent,
			extraVideoAssetsArray
		};
	}
});
